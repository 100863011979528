import React from 'react';
import classNames from 'classnames/bind';
import styles from './Logo.module.css';
import {ReactComponent as LogoIcon} from 'assets/img/logo.svg';

const cx = classNames.bind(styles);

type Props = {
    centered?: boolean;
    white?: boolean;
    isOnboarding?: boolean;
    onboardingLogo?: boolean;
};

export default function Logo(props: Props) {
    const { centered, white, isOnboarding, onboardingLogo } = props;

    const logoClass = cx({
        logo: true,
        centered,
        white,
        isOnboarding,
    });
    const logoImgClass = cx({
        logoImgDefault: true,
        logoImgOnboarding: isOnboarding && onboardingLogo,
    });

    return (
        <div className={logoClass}>
            <LogoIcon className={logoImgClass} />
        </div>
    );
}
