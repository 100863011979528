import React from 'react';
import {Theme} from "types/ui";

interface ISvgModalCloseComponent {
    theme: Theme;
    onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

const SvgModalCloseComponent: React.FC<ISvgModalCloseComponent> = ({ theme, onClick }) => (
    <svg
        style={{ cursor: 'pointer' }}
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.278 10.157l4.596-4.596a1.5 1.5 0 10-2.121-2.122l-4.596 4.597L5.56 3.439a1.5 1.5 0 10-2.122 2.122l4.597 4.596-4.597 4.596a1.5 1.5 0 002.122 2.121l4.596-4.596 4.596 4.596a1.5 1.5 0 002.121-2.121l-4.596-4.596z"
            fill={theme['border-default']}
        />
    </svg>
);

export default SvgModalCloseComponent;
