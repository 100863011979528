import React from 'react';
import { StyledText } from './styles';
import { withTheme } from 'utils';
import { ITextComponent, IText } from './index';

const TextComponent: React.FC<ITextComponent> = ({
    theme,
    type = 'medium-text',
    color = 'text-main',
    opacityValue = '1',
    text,
    uppercase = false,
    bold = false,
    italic = false,
    center = false,
    inline = false,
    mt,
    mb,
    ellipsis,
    children,
    className,
}) => {
    const transparent = 1 - Number(opacityValue);

    return (
        <StyledText
            uppercase={uppercase}
            center={center}
            theme={theme}
            type={type}
            color={color}
            bold={bold}
            italic={italic}
            inline={inline}
            opacityValue={transparent}
            mt={mt}
            mb={mb}
            ellipsis={ellipsis}
            className={className}
        >
            {text || children}
        </StyledText>
    );
};

const Text: React.FC<IText> = withTheme(TextComponent);

Text.displayName = 'Text';

export { Text, TextComponent };
