import React from 'react';
import styles from './CategoryGrid.module.css';
import Category from "../Category";

function CategoryGrid({ data }: any) {
    return (
        <div className={styles.grid}>
            {data.length > 0  ? data.map((item: any, index: number) => (
                <Category
                    id={item.id}
                    key={index}
                    image={item.image}
                    name={item.name}
                    description={item.short_description}
                />
            )) : "No items"}
        </div>
)
}

export default CategoryGrid;
