import React from 'react';
import styles from './Footer.module.css';
import {useNavigate} from "react-router-dom";
import {Text} from "../Common/Text";

const Footer = () =>{
    const navigate = useNavigate();

    return <footer className={styles.footer}>
        <div className={styles.footerContainer}>
            <div className={styles.links}>
                <div onClick={() => navigate('/cookie-policy')}>
                    <Text type="medium-text">Cookie Policy</Text>
                </div>
                <div onClick={() => navigate('/privacy-policy')}>
                    <Text type="medium-text">Privacy Policy</Text>
                </div>
                <div onClick={() => navigate('/terms-of-use')}>
                    <Text type="medium-text">Terms of Use & Service</Text>
                </div>
                <div onClick={() => navigate('/refund-policy')}>
                    <Text type="medium-text">Refund Policy</Text>
                </div>
                <div onClick={() => navigate('/subscription-policy')}>
                    <Text type="medium-text">Subscription policy</Text>
                </div>
            </div>
        </div>
    </footer>;
}

export default Footer;
