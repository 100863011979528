import React, { ReactElement } from 'react';
import { StyledIndicator } from '../styles';
import { Theme } from 'types/ui';

export function StyledIndicatorComp({
    theme,
    left,
}: {
    theme: Theme;
    left?: boolean;
}): ReactElement {
    return (
        <StyledIndicator theme={theme} left={left}>
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z"
                    fill="#000"
                />
            </svg>
        </StyledIndicator>
    );
}
