import { UserStore } from 'types/store/userStore';

import * as actionTypes from './actionTypes';

const initialState: UserStore = {
    userData: {},
    email: '',
    country: localStorage.getItem('country') || 'US'
};

const UserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };
        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };
        default:
            return state;
    }
};

export default UserReducer;
