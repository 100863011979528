import styled from 'styled-components';
import {
    getBackgroundColorType,
    getBorderColorType,
    IStyledImageBox,
    IStyledOption,
} from '../index';

import { Colors } from 'types/commonTypes';

const getBackgroundColor = (data: getBackgroundColorType): Colors => {
    const { checked, backgroundColor, checkedBackgroundColor } = data;

    const defaultColors: Colors = checked ? 'primary-default' : 'surface-default';

    if (backgroundColor && checkedBackgroundColor) {
        const customColors: Colors = checked ? checkedBackgroundColor : backgroundColor;

        return customColors;
    }

    return defaultColors;
};

const getBorderColor = (data: getBorderColorType): Colors => {
    const { borderColor, checkedBorderColor, checked } = data;

    const defaultColors: Colors = checked ? 'primary-default' : 'border-default';

    if (borderColor && checkedBorderColor) {
        return checked ? checkedBorderColor : borderColor;
    }

    return defaultColors;
};

export const StyledOptionBox = styled.div<IStyledOption>`
    position: relative;
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
    width: ${({ short }) => short && 'calc(50% - 6px)'};
`;

export const StyledOption = styled.div<IStyledOption>`
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 80px;
    height: ${({ short }) => short ? '80px' : 'auto'};
    border: solid;
    border-width: ${({ borderWidth }) => `${borderWidth || 2}px`};
    border-color: transparent;
    background-color: ${({ theme, checked, backgroundColor, checkedBackgroundColor }) =>
        theme[getBackgroundColor({ checked, backgroundColor, checkedBackgroundColor })]};

    ${({ theme, borderColor, checkedBorderColor, checked }) =>
        borderColor &&
        checkedBorderColor &&
        `border-color: ${theme[getBorderColor({ borderColor, checkedBorderColor, checked })]};`}

    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: ${({ leftImage, leftIcon, leftSvg, leftCheckbox, short }) => {
        switch (true) {
            case (!!leftIcon || !!leftSvg) && short:
                return '54px';
            case short:
                return '14px';
            case leftCheckbox:
                return '60px';
            case !!leftImage:
                return '100px';
            case !!leftIcon || !!leftSvg:
                return '62px';
            default:
                return '18px';
        }
    }};
    padding-right: ${({ rightImage, rightIcon, rightSvg, rightCheckbox, short }) => {
        switch (true) {
            case (!!rightIcon || !!rightSvg) && short:
                return '54px';
            case short:
                return '14px';
            case rightCheckbox:
                return '50px';
            case !!rightImage:
                return '100px';
            case !!rightIcon || !!rightSvg:
                return '58px';
            default:
                return '18px';
        }
    }};
    transition: 0.1s;
    box-shadow: 0px 6px 12px rgb(56 70 118 / 5%);
`;

export const StyledTitleBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledIconBox = styled.div<IStyledImageBox>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ left, short }) => `${left ? 'left: ' : 'right: '} ${short ? '14px' : '18px'}`};
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
`;

export const StyledImageBox = styled.div<IStyledImageBox>`
    position: absolute;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 100%;
    max-height: 80px;
    bottom: 0;
    align-content: center;
    top: ${({borderWidth}) => `-${borderWidth ? borderWidth : 2}px`};
    ${({ left, borderWidth }) => (`${left ? 'left: ' : 'right: '} -${borderWidth ? borderWidth : 2}px`)} ;
    ${({ left }) => !left && 'margin-left: 5px'};
`;

export const StyledIndicator = styled.div<IStyledImageBox>`
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    ${({ left }) => (left ? 'left: ' : 'right: ')} 18px;
    max-width: 24px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme['primary-default']};
    background-color: ${({ theme }) => theme['surface-main']};

    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
    }

    & svg,
    & svg > * {
        fill: transparent;
    }
`;

export const StyledInput = styled.input<IStyledOption>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 100;
    cursor: pointer;

    &:checked ~ ${StyledOption} {
        ${StyledIconBox} {
            & svg,
            & svg > * {
                fill: ${({ theme, indicated }) =>
                    indicated ? theme['text-default'] : theme['on-primary']};
            }
        }
    }

    &:checked ~ ${StyledOption} ${StyledIndicator} {
        border: 2px solid
            ${({ theme, indicated }) =>
                indicated ? theme['primary-default'] : theme['on-primary']};
        background-color: ${({ theme, indicated }) =>
            indicated ? theme['primary-default'] : theme['on-primary']};
        & svg,
        & svg > * {
            fill: ${({ theme, indicated }) =>
                indicated ? theme['on-primary'] : theme['primary-default']};
        }
    }
    &:hover:not(:checked) ~${StyledOption}{
        background-color: var(--surface-hovered);
        box-shadow: 0px 8px 20px rgba(68,95,194,.15);;
    }
    &:active ~${StyledOption}{
        transform: scale(.99);
    }
`;
