import React, {ReactNode} from 'react';
import styles from './FixedWrapper.module.css';

type Props = {
    children: ReactNode;
}

const FixedWrapper = ({children}: Props) => (
    <div className={styles.wrapper}>
        {children}
    </div>
)

export default FixedWrapper;
