import React, {useEffect, useState} from 'react';
import {getCategories} from "../../../api/appApiService";
import CategoryGrid from "../../../components/CategoriesGrid";
import PageWrapper from "../../../components/PageWrapper";

const Categories = () => {
    const [data, setData] = useState([] as any);
    console.log(data);
    useEffect(() => {
        getCategories().then((data) => {
            setData(data);
        })
    }, []);

    return <PageWrapper isHeaderVisible={true}>
        <CategoryGrid data={data}/>
    </PageWrapper>
};

export default Categories;
