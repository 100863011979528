import styled from 'styled-components';

import { ICheckBoxComponent, ICheckIcon } from './index';
import {transparentize} from "utils/ui";

const getSize = (large: boolean) =>
    large ? 'width: 24px; height: 24px;' : 'width: 16px; height: 16px';

export const StyledCheckIcon = styled.div<ICheckIcon>`
    position: relative;
    display: inline-block;
    ${({ large }) => getSize(large || false)};
    border-radius: ${({ round }) => (round ? '50%' : '4px')};
    border: 2px solid ${({ theme }) => theme['border-default']};
    margin-right: ${({ large }) => (large ? '12px' : '8px')};
    transition: border-color 0.2s, box-shadow 0.2s;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    svg path {
        fill: ${({ theme }) => theme['on-primary']};
    }
`;

export const StyledCheckBox = styled.div<ICheckBoxComponent>`
    position: relative;
    display: flex;
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};

    input[type='checkbox'] {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }

    input[type='checkbox'] ~ label {
        color: ${({ theme }) => theme['text-main']};
    }

    input[type='checkbox']:hover:not(:disabled) ~ ${StyledCheckIcon} {
        border-color: ${({ theme }) => theme['primary-disabled']};
    }

    input[type='checkbox']:disabled ~ ${StyledCheckIcon} {
        border-color: ${({ theme }) => transparentize(0.5, theme['border-default'])};
    }

    input[type='checkbox']:checked ~ ${StyledCheckIcon} {
        border-color: ${({ theme, disabled }) =>
            disabled ? theme['primary-disabled'] : theme['primary-default']};
        background-color: ${({ theme, disabled }) =>
            disabled ? theme['primary-disabled'] : theme['primary-default']};
    }
`;
