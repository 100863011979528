import React from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';

import { IImage } from './index';

import { StyledImage } from './styles';

const Image: React.FC<IImage> = ({
    isForceVisible = false,
    alt,
    src,
    maxWidth,
    maxHeight,
    center = false,
    className,
    mb,
                                     objectFit = 'contain'
}) => {
    if (isForceVisible) forceVisible();

    return (
        <LazyLoad style={{width:'100%', height:'100%'}}>
            <StyledImage
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                center={center}
                src={src}
                alt={alt}
                mb={mb}
                className={className}
                objectFit={objectFit}
            />
        </LazyLoad>
    );
};

export { Image };
