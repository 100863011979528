import * as React from 'react';
import { StyledButton, CenteredLoaderBox, RightIconBox, CenterIconBox } from './styles';
import { withTheme } from 'utils';

import { IIconContent, IButton, IButtonComponent } from './index';

import { Text } from '../Text';
import { Loader } from '../Loader';

const IconContent: React.FC<IIconContent> = ({ size, textColor, text, icon }) => (
    <>
        <Text type={size === 'medium' ? 'medium-button' : 'small-button'} color={textColor}>
            {text}
        </Text>
        <img src={icon} alt="icon" />
    </>
);

const ButtonComponent: React.FC<IButtonComponent> = ({
    onClick,
    backgroundColor = 'primary-default',
    textColor = 'on-primary',
    hoverColor = 'primary-hovered',
    size = 'medium',
    text,
    disabled = false,
    isLoading = false,
    withPulsating = false,
    children,
    rightIcon,
    centerIcon,
    theme,
    dataLocator,
    borderRadius = 'medium',
    className,
    mb,
}) => {
    if (isLoading) {
        return (
            <StyledButton
                onClick={onClick}
                backgroundColor={backgroundColor}
                textColor={textColor}
                hoverColor={hoverColor}
                size={size}
                text={text}
                disabled={disabled || isLoading}
                isLoading={isLoading}
                withPulsating={withPulsating}
                theme={theme}
                rightIcon={rightIcon}
                centerIcon={centerIcon}
                borderRadius={borderRadius}
                className={className}
                data-locator={dataLocator}
                mb={mb}
            >
                <CenteredLoaderBox>
                    <Loader size={28} color={textColor} />
                </CenteredLoaderBox>
            </StyledButton>
        );
    } else {
        if (rightIcon) {
            return (
                <StyledButton
                    onClick={onClick}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    hoverColor={hoverColor}
                    size={size}
                    text={text}
                    disabled={disabled || isLoading}
                    isLoading={isLoading}
                    withPulsating={withPulsating}
                    theme={theme}
                    rightIcon={rightIcon}
                    centerIcon={centerIcon}
                    borderRadius={borderRadius}
                    className={className}
                    data-locator={dataLocator}
                    mb={mb}
                >
                    <RightIconBox>
                        <IconContent
                            size={size}
                            textColor={textColor}
                            text={text || children}
                            icon={rightIcon}
                        />
                    </RightIconBox>
                </StyledButton>
            );
        } else {
            return (
                <StyledButton
                    onClick={onClick}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    hoverColor={hoverColor}
                    size={size}
                    text={text}
                    disabled={disabled || isLoading}
                    isLoading={isLoading}
                    withPulsating={withPulsating}
                    theme={theme}
                    rightIcon={rightIcon}
                    centerIcon={centerIcon}
                    borderRadius={borderRadius}
                    className={className}
                    data-locator={dataLocator}
                    mb={mb}
                >
                    {centerIcon ? (
                        <CenterIconBox>
                            <IconContent
                                size={size}
                                textColor={textColor}
                                text={text || children}
                                icon={centerIcon}
                            />
                        </CenterIconBox>
                    ) : (
                        <Text
                            type={size === 'medium' ? 'medium-button' : 'small-button'}
                            color={textColor}
                        >
                            {text || children}
                        </Text>
                    )}
                </StyledButton>
            );
        }
    }
};

const Button: React.FC<IButton> = withTheme(ButtonComponent);

Button.displayName = 'ButtonWithTheme';

export { Button, ButtonComponent };
