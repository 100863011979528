import React from 'react';
import {useNavigate} from "react-router-dom";
import styles from './Category.module.css';
import {Text} from "../Common";

export type Props = {
    id: string,
    name: string,
    image: string,
    description: string,
}

const Category = ({id, image, name, description}: Props) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/categories/${id}`)
    }

    return (
        <div className={styles.cardContainer} onClick={handleClick}>
            <div className={styles.cardImage}>
                <img src={image} alt={name}/>
                <div className={styles.cardImageCover}/>
            </div>
            <Text type="h6" bold>{name}</Text>
            <Text type="small-text">{description}</Text>
        </div>
    );
};

export default Category;
