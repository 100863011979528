import styled from 'styled-components';

import { IBoxComponent } from './index';

export const StyledBox = styled.div<IBoxComponent>`
    ${({ withShadow }) => withShadow && 'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);'};
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`};
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
    ${({ theme, backgroundColor }) =>
        backgroundColor && `background-color: ${theme[backgroundColor]};`};
    ${({ theme, borderColor }) => borderColor && `border: 1px solid  ${theme[borderColor]};`};
    ${({ paddingTop }) => Number(paddingTop) && `padding-top: ${paddingTop}px;`};
    ${({ paddingX }) =>
        Number(paddingX) && `padding-left: ${paddingX}px; padding-right: ${paddingX}px;`};
    ${({ paddingBottom }) => Number(paddingBottom) && `padding-bottom: ${paddingBottom}px;`};
`;
