import React, {useEffect, useState} from 'react';
import { StyledOptionBox, StyledInput, StyledOption, StyledTitleBox } from './styles';
import { withTheme } from 'utils';
import { Text } from '../../Text';
import { IGetTitleColor, IOnChangeOption, IOption, IOptionComponent } from '../index';
import { getLeftImage, getRightImage } from './helpers';
import {useLocation} from "react-router-dom";

const getTitleColor = ({ indicated, checked }: IGetTitleColor) => {
    return checked && !indicated ? 'on-primary' : 'text-main';
};

const getSubtitleColor = ({ indicated, checked }: IGetTitleColor) => {
    return checked && !indicated ? 'on-primary' : 'text-secondary';
};

const OptionComponent: React.FC<IOptionComponent> = ({
    theme,
    title = 'Title',
    subtitle,
    onClick,
    value,
    leftIcon,
    leftSvg,
    leftImage,
    rightImage,
    rightIcon,
    rightSvg,
    indicated = false,
    rightCheckbox,
    leftCheckbox,
    titleType,
    mb,
    dataLocator,
    short,
    borderWidth,
    borderColor,
    backgroundColor,
    checkedBackgroundColor,
    checkedBorderColor,
    className,
}) => {
    const [checked, setChecked] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setChecked(false);
    }, [location]);

    const onChange = ({ target: { value } }: IOnChangeOption) => {
        setChecked(!checked);

        if (onClick) onClick(value, !checked);
    };

    const defaultTitleType = 'medium-text';

    return (
        <StyledOptionBox
            mb={mb}
            short={short}
            className={className}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            checkedBackgroundColor={checkedBackgroundColor}
            checkedBorderColor={checkedBorderColor}
        >
            <StyledInput
                value={value}
                theme={theme}
                type="checkbox"
                onChange={onChange}
                indicated={indicated}
                borderColor={borderColor}
                data-locator={dataLocator}
                backgroundColor={backgroundColor}
                checkedBorderColor={checkedBorderColor}
                checkedBackgroundColor={checkedBackgroundColor}
                checked={checked}
            />
            <StyledOption
                theme={theme}
                leftSvg={leftSvg}
                leftIcon={leftIcon}
                leftImage={leftImage}
                rightSvg={rightSvg}
                rightIcon={rightIcon}
                rightImage={rightImage}
                indicated={indicated}
                checked={checked}
                rightCheckbox={rightCheckbox}
                leftCheckbox={leftCheckbox}
                short={short}
                borderColor={borderColor}
                checkedBorderColor={checkedBorderColor}
                borderWidth={borderWidth}
                backgroundColor={backgroundColor}
                checkedBackgroundColor={checkedBackgroundColor}
            >
                <StyledTitleBox>
                    <Text
                        type={titleType || defaultTitleType}
                        text={title}
                        bold
                        color={getTitleColor({ indicated, checked })}
                        mb={(subtitle && 4) || undefined}
                        ellipsis={subtitle ? 1 : 2}
                    >
                        {title}
                    </Text>
                    {subtitle && (
                        <Text
                            type="small-text"
                            ellipsis={2}
                            color={getSubtitleColor({ indicated, checked })}
                        >
                            {subtitle}
                        </Text>
                    )}
                </StyledTitleBox>
                {getLeftImage({
                    theme,
                    leftIcon,
                    leftSvg,
                    leftImage,
                    leftCheckbox,
                    short,
                    borderWidth
                })}
                {getRightImage({
                    theme,
                    rightIcon,
                    rightSvg,
                    rightImage,
                    rightCheckbox,
                    short,
                    borderWidth,
                })}
            </StyledOption>
        </StyledOptionBox>
    );
};

const Option: React.FC<IOption> = withTheme(OptionComponent);

Option.displayName = 'Option';

export { Option, OptionComponent };
