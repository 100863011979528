import {Theme} from 'types/ui';

export const defaultThemeConfig: Theme = {
    'primary-default': '#8F7AFF',
    'primary-hovered': '#8571ed',
    'primary-active': '#8F7AFF',
    'primary-disabled': '#C3B8FF',
    'secondary-default': '#FF964F',
    'secondary-hovered': '#FFA466',
    'secondary-active': '#F9802F',
    'secondary-disabled': '#BDC3C7',
    'surface-main': '#FFFFFF',
    'surface-secondary': '#F6F6F8',
    'surface-default': '#F3F5F7',
    'surface-hovered': '#F9F9FA',
    'text-main': '#161616',
    'text-secondary': '#383838',
    'text-secondary-subdued': '#7D8896',
    'border-default': '#D4DAE0',
    'border-subdued': '#F3F5F7',
    'critical-default': '#E74C3C',
    'critical-highlight': '#FFC2CA',
    'critical-surface': '#FEF5F5',
    'warning-default': '#FEE14B',
    'warning-highlight': '#FFF9E9',
    'warning-surface': '#FFF9E9',
    'success-default': '#49C95F',
    'success-highlight': '#B0E8BA',
    'success-surface': '#F2FCF4',
    'on-primary': '#FFFFFF',
    discount: '#7F8C8D', // Dark pastel gray for discount color
    rating: '#FF964F', // Dark pastel yellow for rating color
    safe: '#154360', // Dark pastel navy blue for safe color
    'safe-surface': '#1B4F72', // Even darker pastel navy blue for safe surface color
    'default-indent': '16', // Default indent
    'border-radius-small': '24', // Border radius small
    'border-radius-medium': '48', // Border radius medium
    'default-box-shadow': '0 8px 16px rgba(127, 140, 141, 0.25)', // Default box shadow with a tint of primary color
    'button-max-width': '560px',
};
