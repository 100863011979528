import './App.css';
import {ThemeProvider} from "./utils";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {defaultThemeConfig} from "./defaultTheme";
import PrivacyPolicy from "./pages/OtherPages/PrivacyPolicy";
import Landing from "./pages/OtherPages/Landing";
import TermsOfUse from "./pages/OtherPages/TermsOfUse";
import CookiePolicy from "./pages/OtherPages/CookiePolicy";
import SubscriptionPolicy from "./pages/OtherPages/SubscriptionPolicy";
import RefundPolicy from "./pages/OtherPages/RefundPolicy";
import Categories from "./pages/AppPages/Categories";
import Profile from "./pages/AppPages/Profile";
import Courses from "./pages/AppPages/Courses";
import Lesson from "./pages/AppPages/Lesson";
import {AnimatePresence} from "framer-motion";

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Landing/>,
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy/>,
        },
        {
            path: '/terms-of-use',
            element: <TermsOfUse/>,
        },
        {
            path: '/cookie-policy',
            element: <CookiePolicy/>,
        },
        {
            path: '/subscription-policy',
            element: <SubscriptionPolicy/>,
        },
        {
            path: '/refund-policy',
            element: <RefundPolicy/>,
        },
        {
            path: '/profile',
            element: <Profile/>,
        },
        {
            path: '/categories',
            element: <Categories/>,
        },
        {
            path: '/categories/:id',
            element: <Courses/>,
        },
        {
            path: '/lesson/:id',
            element: <Lesson/>,
        },
    ]);

    return (
        <ThemeProvider theme={defaultThemeConfig}>
            <AnimatePresence>
                <RouterProvider router={router}/>
            </AnimatePresence>
        </ThemeProvider>
    );
}

export default App;
