import styled from 'styled-components';
import { IOptionBoxButton, IStyledOptionBox } from '../index';

export const StyledOptionBoxButton = styled.div<IOptionBoxButton>`
    ${({ fixed }) =>
        fixed &&
        `
        position: fixed;
        bottom: 0;
    `};
    z-index: 10000;
    ${({ theme, fixed }) =>
        `padding:
        ${fixed ? theme['default-indent'] : 0}px;`}

    width: 100%;
    left: 0;
    display: flex;
    align-items: flex-end;
    background: var(--surface-main);
    ${({ fixed }) => fixed && `border-top: 1px solid var(--border-default)`};
    box-shadow: rgb(0 0 0 / 16%) 0 0 6px;
    button {
        margin: 0 auto;
    }
`;

export const StyledOptionBox = styled.div<IStyledOptionBox>`
    ${({ fixed }) =>
        fixed &&
        `
        padding-bottom: 97px;
    `};
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
`;
