import styled from 'styled-components';

import { IStyledButton, Size } from './index';

const getSize = (size: Size) => {
    switch (size) {
        case 'medium': {
            return `
                padding: 20px;
                min-height: 64px;
            `;
        }
        case 'small': {
            return `
                padding: 4px 16px;
                max-width: 160px;
                min-height: 44px;
            `;
        }
    }
};

export const StyledButton = styled.button<IStyledButton>`
    position: relative;
    cursor: pointer;
    background-color: ${({ theme, backgroundColor = 'primary-default' }) => theme[backgroundColor]};
    border-radius: ${({ borderRadius, theme }) =>
        borderRadius === 'small'
            ? `${theme['border-radius-small']}px`
            : `${theme['border-radius-medium']}px`};

    color: ${({ theme, textColor = 'on-primary' }) => theme[textColor]};
    width: 100%;
    ${({ size = 'medium' }) => getSize(size)};
    ${({ mb }) => mb && `margin-bottom: ${mb}px`};
    word-break: break-word;
    max-width: ${({ theme}) => theme['button-max-width']};
    &:hover:not(:disabled) {
        background-color: ${({ theme, hoverColor = 'primary-hovered' }) => theme[hoverColor]};
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${({ theme, isLoading }) =>
            isLoading ? theme['primary-active'] : theme['primary-disabled']};
        animation: none;
    }

    ${({ withPulsating, isLoading, backgroundColor = 'primary-default', theme }) =>
        withPulsating &&
        !isLoading &&
        `animation: pulsar 2s infinite; box-shadow: 0 0 0 0 ${theme[backgroundColor]};`}

    ${({ rightIcon }) => rightIcon && 'padding-right: 42px; text-align: left;'};

    &:active {
        transform: scale(.97);
    }
    
    @keyframes pulsar {
        to {
            box-shadow: 0 0 0 14px transparent;
        }
    }
`;

export const CenteredLoaderBox = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const RightIconBox = styled.div`
    & img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
`;

export const CenterIconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        height: 100%;
        margin-left: 6px;
    }
`;
