import React from 'react';
import { StyledIconBox } from './styles';

// styled components
import { StyledIndicatorComp, StyledImageBoxComp } from './subcomponents';
import {IGetImage} from "../types";

export const getLeftImage = ({
    theme,
    leftIcon,
    leftSvg,
    leftImage,
    leftCheckbox,
    short,
    borderWidth
}: IGetImage) => {
    if (leftCheckbox) {
        return <StyledIndicatorComp left theme={theme} />;
    } else {
        if (leftImage) {
            return <StyledImageBoxComp borderWidth={borderWidth} left image={leftImage} />;
        } else {
            if (leftIcon) {
                return (
                    <StyledIconBox left short={short}>
                        {leftIcon}
                    </StyledIconBox>
                );
            } else {
                // @ts-ignore
                return leftSvg ? <StyledIconBox left>{leftSvg}</StyledIconBox> : null;
            }
        }
    }
};

export const getRightImage = ({
    theme,
    rightImage,
    rightIcon,
    rightSvg,
    rightCheckbox,
    short,
    borderWidth,
}: IGetImage) => {
    if (rightCheckbox) {
        return <StyledIndicatorComp theme={theme} />;
    } else if (rightImage) {
        return <StyledImageBoxComp borderWidth={borderWidth} image={rightImage} />;
    } else {
        if (rightIcon) {
            return (
                <StyledIconBox short={short}>
                    {rightIcon}
                </StyledIconBox>
            );
        } else {
            // @ts-ignore
            return rightSvg ? <StyledIconBox>{rightSvg}</StyledIconBox> : null;
        }
    }
};
