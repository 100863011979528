import styled from 'styled-components';

import { IHeaderComponent } from './index';

export const StyledHeader = styled.header<IHeaderComponent>`
    padding: 0 ${({ theme }) => (theme['default-indent'] || '0') + 'px'};
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    ${({ fixed = false }) =>
        fixed &&
        `
        width: 100%;
        position: fixed;
        top: 0;
    `};
    ${({ withShadow }) => withShadow && 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);'};
    ${({ theme, backgroundColor }) =>
        backgroundColor && `background-color: ${theme[backgroundColor]}`};
`;

export const StyledChildBlock = styled.div`
    width: 100%;
    max-width: calc(50% - 4px);
    &:last-child {
        display: flex;
        justify-content: flex-end;
    }
`;
