import React from 'react';
import styles from "./Profile.module.css";
import {Button, Text} from "components/Common";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectEmail} from "../../../redux/User/selectors";
import {ReactComponent as ProfileIcon} from "./img/profile.svg";
import {ReactComponent as AccountIcon} from "./img/account.svg";
import {ReactComponent as ContactUsIcon} from "./img/countact-us.svg";
import {ReactComponent as FaqIcon} from "./img/faq.svg";
import {ReactComponent as RightIcon} from "assets/img/arrow-right.svg";
import PageWrapper from "../../../components/PageWrapper";

function Profile() {
    const navigate = useNavigate();
    const email = useSelector(selectEmail);

    return <PageWrapper isHeaderVisible={true}>
        <Text type="h3" mb={36}>Profile</Text>
        <div className={styles.profileTop}>
            <div className={styles.avatar}>{email ? Array.from(email)[0] : 'N'}</div>
            <Text>{email || 'New user'}</Text>
        </div>
        <div className={styles.profileBlock}>
            <Text type="h6" mb={24} mt={40}>Account Setting</Text>
            <div className={styles.profileBlockLink} onClick={() => navigate('/profile/personal-data')}>
                <div>
                    <ProfileIcon/>
                    Personal data
                </div>
                <div>
                    <RightIcon/>
                </div>
            </div>
            <div className={styles.profileBlockLink} onClick={() => navigate('/profile/account')}>
                <div>
                    <AccountIcon/>
                    Account
                </div>
                <div>
                    <RightIcon/>
                </div>
            </div>
        </div>
        <div className={styles.profileBlock}>
            <Text type="h6" mb={24} mt={40}>Other</Text>
            <div className={styles.profileBlockLink} onClick={() => navigate('/faq')}>
                <div>
                    <FaqIcon/>
                    FAQ
                </div>
                <div>
                    <RightIcon/>
                </div>
            </div>
            <div className={styles.profileBlockLink}>
                <div>
                    <ContactUsIcon/>
                    Contact Us
                </div>
                <div>
                    <RightIcon/>
                </div>
            </div>
        </div>
        <div className={styles.profileBottom}>
            <Text type="caption" center color="text-secondary-subdued" mb={24}>
                <>
                    <a
                        href={'https://' + process.env.REACT_APP_MAIN_DOMAIN + "/terms-of-use"}
                        // className={base.colorBrand}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-locator="termsOfUseLink"
                    >
                        Terms of Use
                    </a>
                    &nbsp; | &nbsp;
                    <a
                        href={'https://' + process.env.REACT_APP_MAIN_DOMAIN + "/privacy-policy"}
                        // className={base.colorBrand}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-locator="privacyPolicyLink"
                    >
                        Privacy Policy
                    </a>
                    &nbsp; | &nbsp;
                    <a
                        href={'https://' + process.env.REACT_APP_MAIN_DOMAIN + "/refund-policy"}
                        // className={base.colorBrand}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-locator="refundPolicyLink"
                    >
                        Refund Policy
                    </a>
                </>
            </Text>
            <Button text="Logout" onClick={() => navigate('/logout')} mb={24}/>
        </div>
    </PageWrapper>
}

export default Profile;
