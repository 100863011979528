import styled from 'styled-components';
import { ILoaderComponent } from './index';

export const StyledLoader = styled.svg<ILoaderComponent>`
    overflow: hidden;
    width: ${({ size }) => size + 'px'};
    height: ${({ size }) => size + 'px'};

    path {
        fill: ${({ theme, color = 'primary-default' }) => theme[color]};
    }
`;
