import styled from 'styled-components';
import { IStyledModal, IStyledModalBox } from './index';

export const StyledModalContainer = styled.div<IStyledModal>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ isModalOpen }) => isModalOpen && 'opacity: 1; visibility: visible;'}
`;

export const StyledModalBox = styled.div<IStyledModalBox>`
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    border-color: ${({ theme, borderColor }) => theme[borderColor]};
    background-color: ${({ theme, popupBackgroundColor }) => theme[popupBackgroundColor]};
    padding: ${({ theme, paddingY }) =>
            typeof paddingY === 'number' ? `${paddingY}px` : `${theme['default-indent']}px`}
        ${({ theme, paddingX }) =>
            typeof paddingX === 'number' ? `${paddingX}px` : `${theme['default-indent']}px`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`};
    ${({ withShadow }) => withShadow && 'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);'};
`;
