import {transparentize} from "utils/ui";
import styled from 'styled-components';
import { IStyledRadioButton, IStyledRadioRound } from './index';

const getSize = (size: number | string) => {
    return `
            width: ${size}px;
            height: ${size}px;
        `;
};

export const StyledRadioRound = styled.div<IStyledRadioRound>`
    position: relative;
    ${({ size = 24 }) => getSize(size)};
    background-color: ${({ theme }) => theme['on-primary']};
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme['border-default']};
    margin-right: 12px;
    flex: none;

    &:after {
        content: '';
        position: absolute;
        width: 60%;
        height: 60%;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme['primary-default']};
        opacity: 0;
    }
`;

export const StyledRadioButton = styled.div<IStyledRadioButton>`
    position: relative;
    display: flex;
    align-items: ${({ alignLabel }) => alignLabel};
    font-family: 'DM Sans', sans-serif;
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};

    input[type='radio'] {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }

    &:hover {
        ${StyledRadioRound} {
            border-color: ${({ theme }) => theme['primary-disabled']};
        }
    }

    input[type='radio']:checked ~ ${StyledRadioRound} {
        border-color: ${({ theme }) => theme['primary-default']};
        &:after {
            opacity: 1;
        }
    }

    input[type='radio']:disabled ~ ${StyledRadioRound} {
        border-color: ${({ theme }) => transparentize(0.5, theme['border-default'])};
        &:after {
            opacity: 0;
        }
    }
`;
