import { createSelector } from 'reselect';

import { UserStore } from 'types/store/userStore';

import { RootState } from '../rootReducer';

const selectUser = (state: RootState) => <UserStore>state.user;

const selectUserData = createSelector(selectUser, (uiEffects) => uiEffects.userData);
const selectEmail = createSelector(selectUser, (user) => user.email);
const selectCountry = createSelector(selectUser, (user) => user.country);

export {
    selectUser,
    selectEmail,
    selectCountry,
    selectUserData
};
