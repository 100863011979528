import React from 'react';
import { ICheckBox, ICheckBoxComponent, ICheckIcon } from './index';

import { StyledCheckBox, StyledCheckIcon } from './styles';

import { withTheme } from 'utils';
import { Text } from '../Text';

const CheckBoxIcon: React.FC<ICheckIcon> = ({ theme, large, round }) => (
    <StyledCheckIcon theme={theme} large={large} round={round}>
        {large ? (
            <svg width="12" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.937 4.169a1.105 1.105 0 00-1.605 0 1.213 1.213 0 000 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 000-1.668 1.105 1.105 0 00-1.605 0L4.16 6.48 1.937 4.169z"
                    fill={theme['on-primary']}
                />
            </svg>
        ) : (
            <svg width="10" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.75 3.457a.94.94 0 00-1.284 0 .837.837 0 000 1.224L3.529 7.6l6.005-5.723a.837.837 0 000-1.224.94.94 0 00-1.284 0l-4.72 4.5-1.78-1.696z"
                    fill={theme['on-primary']}
                />
            </svg>
        )}
    </StyledCheckIcon>
);

const CheckBoxComponent: React.FC<ICheckBoxComponent> = ({
    theme,
    onChange,
    name,
    label,
    color = 'primary-default',
    defaultChecked = false,
    large = false,
    round = false,
    disabled = false,
    mb,
    className,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked: targetChecked } = e.target;

        if (onChange) onChange(name, targetChecked);
    };

    const checkboxId = `checkbox_${name}`;

    return (
        <StyledCheckBox
            disabled={disabled}
            theme={theme}
            large={large}
            name={name}
            color={color}
            round={round}
            className={className}
            mb={mb}
        >
            <input
                name={name}
                defaultChecked={defaultChecked}
                onChange={handleChange}
                id={checkboxId}
                type="checkbox"
                disabled={disabled}
                data-locator={checkboxId}
            />
            <CheckBoxIcon theme={theme} large={large} round={round} />
            <label htmlFor={checkboxId}>
                <Text type={large ? 'medium-text' : 'caption'} opacityValue={disabled ? 0.5 : 1}>
                    {label}
                </Text>
            </label>
        </StyledCheckBox>
    );
};

const CheckBox: React.FC<ICheckBox> = withTheme(CheckBoxComponent);

export { CheckBox, CheckBoxComponent };
