import React, {ReactNode} from 'react';
import styles from './DocList.module.css';

type ListProps = {
    children?: ReactNode;
};

export default function DocList({children}: ListProps) {
    return (
        <ul className={styles.list}>
            {children}
        </ul>
    );
}
