import React from 'react';
import { withTheme } from 'utils';
import { StyledBox } from './styles';
import { IBoxComponent, IBox } from './index';

const BoxComponent: React.FC<IBoxComponent> = ({
    theme,
    withShadow,
    borderRadius = 0,
    mb = 0,
    backgroundColor,
    borderColor,
    paddingX,
    paddingTop,
    paddingBottom,
    className,
    children,
}) => {
    return (
        <StyledBox
            theme={theme}
            withShadow={withShadow}
            borderRadius={borderRadius}
            paddingX={paddingX}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            mb={mb}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            className={className}
        >
            {children}
        </StyledBox>
    );
};

const Box: React.FC<IBox> = withTheme(BoxComponent);

Box.displayName = 'Box';

export { Box, BoxComponent };
