import * as React from 'react';

import { IRadioButtonComponent, IRadioButton } from './index';

import { withTheme } from 'utils';
import { StyledRadioButton, StyledRadioRound } from './styles';
import { Text } from '../Text';

const RadioButtonComponent: React.FC<IRadioButtonComponent> = ({
    theme,
    onChange,
    name,
    label,
    labelType,
    alignLabel = 'center',
    size = 24,
    value = name,
    disabled = false,
    defaultChecked = false,
    mb,
    className,
}) => {
    const handleChange = (e: any) => {
        const { name, value } = e.target;

        onChange && onChange(name, value);
    };
    const radioButtonId = `radioButton_${name}_${value}`;

    return (
        <StyledRadioButton theme={theme} alignLabel={alignLabel} className={className} mb={mb}>
            <input
                name={name}
                onChange={handleChange}
                disabled={disabled}
                type="radio"
                className="optionInput"
                id={radioButtonId}
                data-locator={radioButtonId}
                value={value || name}
                defaultChecked={defaultChecked}
            />
            <StyledRadioRound size={size} theme={theme} />
            {label && (
                <label htmlFor={radioButtonId}>
                    <Text type={labelType} opacityValue={disabled ? 0.5 : 1}>
                        {label}
                    </Text>
                </label>
            )}
        </StyledRadioButton>
    );
};

const RadioButton: React.FC<IRadioButton> = withTheme(RadioButtonComponent);

export { RadioButton, RadioButtonComponent };
