import styled from 'styled-components';

// index
import { ICloseIcon, ICloseXIcon, ILabel, IStyledInput, IUnitBox } from './index';

const getRightPadding = (data: {
    rightIcon: string | undefined;
    isFocus: boolean | undefined;
    unit: string | undefined;
}) => {
    const { rightIcon, isFocus, unit } = data;

    const defaultPadding = unit ? '37px' : '15px';
    const focusedPadding = unit ? '70px' : '51px';

    return isFocus || rightIcon ? focusedPadding : defaultPadding;
};

export const StyledInputWrapper = styled.div`
    position: relative;
    margin-bottom: 40px;
    padding-top: 20px;
`;

export const StyledHelperText = styled.p`
    position: absolute;
    bottom: 0;
    padding-left: 12px;
    transform: translateY(calc(100% + 4px));
`;

export const StyledInputBox = styled.p`
    position: relative;
`;

export const StyledInputLabel = styled.label<ILabel>`
    display: inline-block;
    font-family: 'DM Sans', sans-serif;
    padding-left: 12px;
    transform: translateY(calc(-100% - 4px));
    color: ${({ theme }) => theme['text-secondary']};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
`;

export const StyledLeftIconBox = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    width: 20px;
    height: 20px;
`;
export const StyledRightIconBox = styled.div`
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    height: 20px;
`;

export const StyledIcon = styled.img<ICloseIcon>`
    width: 100%;
    height: auto;
    object-fit: contain;
`;

export const StyledUnitBox = styled.p<IUnitBox>`
    display: flex;
    align-items: center;
`;

export const StyledInput = styled.input<IStyledInput>`
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ theme }) => `${theme['border-radius-medium']}px`};
    background-color: ${({ theme }) => theme['surface-default']};
    border-color: ${({ theme }) => theme['border-default']};
    color: ${({ theme }) => theme['text-main']};
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: ${({ rightIcon, isFocus, unit }) =>
        getRightPadding({ rightIcon, isFocus, unit })};
    padding-left: ${({ leftIcon }) => (leftIcon ? '43px' : '15px')};

    &::placeholder {
        font-size: 16px;
        line-height: 24px;
        color: ${({ theme }) => theme['text-secondary-subdued']};
        transition: opacity 0.1s linear;
    }

    &:focus::placeholder {
        opacity: 0;
    }

    &:focus {
        background-color: ${({ theme }) => theme['surface-hovered']};
        border-color: ${({ theme }) => theme['safe']};
    }

    &:disabled {
        background-color: ${({ theme }) => theme['surface-secondary']};
        border-color: transparent;
    }

    &:disabled::placeholder,
    &:disabled {
        color: ${({ theme }) => theme['text-secondary-subdued']};
    }

    ${({ theme, errorStatus, touched }) =>
        errorStatus &&
        touched &&
        `background-color: ${theme['surface-hovered']};
            border-color: ${theme['critical-highlight']};`};

    ${({ theme, successStatus }) =>
        successStatus && `border-color: ${theme['success-highlight']};`};
`;
