import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { persistStore, persistReducer } from 'redux-persist';
import rootSaga from './redux/sagas';
import rootReducer from './redux/rootReducer';

// Remove merchantData field from persist store
const saveMerchantDataBlacklistFilter = createBlacklistFilter('payment', ['merchantData']);

const persistConfig = {
    key: 'root',
    storage,
    transforms: [saveMerchantDataBlacklistFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
})


sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
