// appApiService.js
import axios from 'axios';

axios.defaults.baseURL = 'https://mindwayassets.s3.eu-central-1.amazonaws.com'; // Set your base URL

export const getCategories = async () => {
    const response = await axios.get('/app/categories.json');
    return response.status === 200 ? response.data : [];
};

export const getCategoryDetails = async (categoryId) => {
    const response = await axios.get('/app/categories.json');
    const categories = response.status === 200 ? response.data : [];

    return categories.find(course => course.id === categoryId);
};

export const getCourses = async (categoryId) => {
    const response = await axios.get('/app/courses.json');
    const courses = response.status === 200 ? response.data : [];

    return courses.filter(course => course.category_id === categoryId);
};

export const getLessons = async (courseIds) => {
    const response = await axios.get('/app/lessons.json');
    const lessons = response.status === 200 ? response.data : [];
    const lessonsData = {};

    courseIds.forEach(id => {
        lessonsData[id] = lessons.filter(lesson => lesson.course_id === id);
    });

    return lessonsData;
};

export const getLessonData = async (lessonId) => {
    const response = await axios.get('/app/lessons.json');
    const lessons = response.status === 200 ? response.data : [];

    return lessons.find(lesson => lesson.id === lessonId);
}
export const getCategoryUserData = async (categoryId) => {
    if (categoryId === 1) {
        return {
            lastViewedLesson: 3,
            lessons: [
                {
                    id: 1,
                    sectionsCompleted: 4
                },
                {
                    id: 3,
                    sectionsCompleted: 10
                }
            ]
        }
    } else {
        return {
            lastViewedLesson: null,
            lessons: []
        }
    }

}