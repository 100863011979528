import React from 'react';
import styles from './LessonHeader.module.css';
import {ReactComponent as BackIcon} from 'assets/img/icon-back.svg';
import {ReactComponent as CloseIcon} from 'assets/img/close.svg';


type Props = {
    currentIndex: number;
    total: number;
    onBackButton: () => void
    onCloseButton: () => void
}

const LessonHeader = ({currentIndex, total, onBackButton, onCloseButton}: Props) => {
    const calculateProgressWidth = () => (currentIndex / total) * 100 + '%';

    return <header className={styles.header}>
        <div className={styles.headerContainer}>
            <div className={styles.top}>
                {currentIndex !== 0 && <div onClick={onBackButton}>
                    <BackIcon className={styles.iconBack}/>
                </div>}
                <div className={styles.progressBox}>
                    <div className={styles.progressBar} style={{width: calculateProgressWidth()}}/>
                </div>
                <div onClick={onCloseButton}>
                    <CloseIcon className={styles.iconClose}/>
                </div>
            </div>
        </div>
    </header>;
};

export default LessonHeader;
