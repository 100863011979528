import React, {useEffect, useState} from 'react';

import {getHelperTextType, IInput} from './index';
import {InputChangeEvent} from 'types/commonTypes';

import {
    StyledInput,
    StyledInputWrapper,
    StyledHelperText,
    StyledInputLabel,
    StyledInputBox,
    StyledLeftIconBox,
    StyledRightIconBox,
    StyledIcon,
    StyledUnitBox,
} from './styles';

import {withTheme} from 'utils';

import {ReactComponent as CloseIcon} from 'assets/img/close.svg';
import {ReactComponent as ShowPasswordIcon} from 'assets/img/showPassword.svg';
import {ReactComponent as HidePasswordIcon} from 'assets/img/hidePassword.svg';

import {Text} from '../Text';

const getLeftIcon = (leftIcon: string) => {
    return (
        leftIcon && (
            <StyledLeftIconBox>
                <StyledIcon src={leftIcon} alt="icon"/>
            </StyledLeftIconBox>
        )
    );
};

const getHelperText = (data: getHelperTextType) => {
    const {
        errorStatus,
        successStatus,
        touched,
        helperMessage,
        errorMessage,
        successMessage,
    } = data;

    let message = helperMessage;

    if (touched) {
        if (errorStatus) {
            message = errorMessage;
        }

        if (successStatus) {
            message = successMessage;
        }
    }

    const successColors = successStatus ? 'success-default' : 'text-secondary-subdued';
    const helperTextColor = errorStatus ? 'critical-default' : successColors;

    return (
        <Text bold type="caption" color={helperTextColor}>
            {message}
        </Text>
    );
};

const InputComponent: React.FC<IInput> = ({
                                              theme,
                                              type = 'text',
                                              name = '',
                                              errorStatus = false,
                                              successStatus = false,
                                              touched = true,
                                              placeholder = '',
                                              errorMessage = '',
                                              successMessage = '',
                                              helperMessage = '',
                                              label = '',
                                              leftIcon,
                                              rightIcon,
                                              onChange,
                                              onBlur,
                                              disabled,
                                              onFocus,
                                              unit,
                                              ref,
                                              dataLocator,
                                              className,
                                              initialValue = '',
                                              onClear,
                                          }) => {
    const isPassword = type === 'password';

    const [isFocus, setIsFocus] = useState(false);
    const [actualType, setActualType] = useState(type);
    const [value, setValue] = useState(initialValue);

    // this effect handles situation when user switch between metric and imperial system in user account
    // we need to change weight field value in this case, because we have one field with dynamically changes initialValue
    useEffect(() => {
        if (initialValue !== value) {
            setValue(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        setActualType(type);
    }, [type]);

    const onBlurHandler = (e: InputChangeEvent) => {
        if (onBlur) {
            onBlur(e);
        }

        setTimeout(() => {
            setIsFocus(false);
        }, 150);
    };

    const onChangeHandler = (e: InputChangeEvent) => {
        const incomeValue = e.target.value;
        setValue(incomeValue);

        onChange(e);
    };

    const onFocusHandler = (e: InputChangeEvent) => {
        setIsFocus(true);

        if (onFocus) {
            onFocus(e);
        }
    };

    const passwordShowHandler = () => {
        if (!disabled) {
            const currentType = actualType === 'password' ? 'text' : 'password';

            setActualType(currentType);
        }
    };

    const getShowPasswordIco = () => {
        if (actualType === 'password' && !disabled) {
            return <ShowPasswordIcon
                style={{
                    width: '100%',
                    height: 'auto',
                }}
                onClick={passwordShowHandler}/>;
        }

        return <HidePasswordIcon style={{
            width: '100%',
            height: 'auto',
        }}
                                 onClick={passwordShowHandler}/>;
    };

    const onClearHandler = () => {
        setValue('');

        if (onClear) {
            onClear();
        }
    };

    return (
        <StyledInputWrapper>
            {label && (isFocus || value) && (
                <StyledInputLabel theme={theme} touched={touched} htmlFor={name}>
                    {label}
                </StyledInputLabel>
            )}
            <StyledInputBox>
                <StyledInput
                    theme={theme}
                    placeholder={placeholder}
                    errorStatus={errorStatus}
                    successStatus={successStatus}
                    type={actualType}
                    name={name}
                    id={name}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    touched={touched}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    ref={ref}
                    onFocus={onFocusHandler}
                    disabled={disabled}
                    unit={unit}
                    isFocus={isFocus}
                    value={value}
                    data-locator={dataLocator}
                    className={className}
                />
                {leftIcon && getLeftIcon(leftIcon)}
                <StyledRightIconBox>
                    {Boolean(unit) && (
                        <StyledUnitBox isFocus={isFocus} isRightIcon={Boolean(rightIcon)}>
                            <Text color="text-secondary-subdued" type="medium-text">
                                {unit}
                            </Text>
                        </StyledUnitBox>
                    )}

                    {isPassword && (
                        getShowPasswordIco()
                    )}

                    {!isPassword && (
                        <>
                            {typeof rightIcon === 'string' && !unit && (
                                <StyledIcon src={rightIcon} alt="icon"/>
                            )}
                            <div data-locator="clearButton">
                                <CloseIcon onClick={onClearHandler} style={{
                                    transition: '0.1s',
                                    width: isFocus ? '20px' : 0,
                                    marginLeft: isFocus ? '18px' : 0,
                                    opacity: isFocus ? 1 : 0,
                                    visibility: isFocus ? 'visible' : 'hidden'
                                }}/>
                            </div>
                        </>
                    )}
                </StyledRightIconBox>
            </StyledInputBox>
            <StyledHelperText>
                {getHelperText({
                    errorStatus,
                    successStatus,
                    touched,
                    helperMessage,
                    errorMessage,
                    successMessage,
                })}
            </StyledHelperText>
        </StyledInputWrapper>
    );
};

const Input: React.FC<IInput> = withTheme(InputComponent);

Input.displayName = 'InputWithTheme';

export {Input, InputComponent};
