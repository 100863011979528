import { combineReducers } from '@reduxjs/toolkit';

import UserReducer from './User/reducer';

const appReducer = combineReducers({
    user: UserReducer
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'RESET_STATE') {
        localStorage.removeItem('isResetStore');

        state = undefined;
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
