import React, {useEffect, useRef, useState} from 'react';
import {getLessonData} from "../../../api/appApiService";
import PageWrapper from "../../../components/PageWrapper";
import styles from './Lesson.module.css'
import {Box, Button, IOption, OptionBox, Text} from "../../../components/Common";
import ScrollToTop from "../../../components/ScrollToTop";
import FixedWrapper from "../../../components/FixedWrapper";
import LessonHeader from "../../../components/LessonHeader";
import {useNavigate, useParams} from "react-router-dom";
import {Colors, TextType} from "../../../types/commonTypes";
import {motion} from 'framer-motion';

type QuizProps = {
    question: string,
    answer: string,
    correctOption: any,
    optionData: Array<IOption>
}

type BlockProps = {
    type: 'text' | 'image' | 'quiz',
    subtype: TextType | 'html'
    value: string | QuizProps,
}

const Lesson = () => {
    const blocksRefs = useRef<(HTMLDivElement | null)[]>([]);
    const navigate = useNavigate();
    const {id} = useParams();
    const [data, setData] = useState([] as any);
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [currentBlock, setCurrentBlock] = useState(0 as number);
    const [isContinueDisabled, setIsContinueDisabled] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (blocksRefs.current[currentBlock]) {
                const targetPosition = blocksRefs.current[currentBlock]!.getBoundingClientRect().top + window.scrollY;
                const desiredPosition = targetPosition - 80;
                window.scrollTo({top: desiredPosition, behavior: 'smooth'});
            }
        }, 100);

    }, [currentBlock]);

    useEffect(() => {
        if (id) {
            getLessonData(parseInt(id)).then((data) => {
                setData(data["sections"]);
            })
        }
    }, [id]);

    const handleContinue = () => {
        if (currentSection <= data.length - 1) {
            if (currentBlock < data[currentSection]?.blocks.length - 1) {
                setCurrentBlock(prev => prev + 1);
            } else {
                setCurrentBlock(0);
                setCurrentSection(prev => prev + 1);
            }
            if (data[currentSection + 1] && data[currentSection + 1][currentBlock] && !!data[currentSection + 1][currentBlock].find((el: any) => el.type === "quiz")) {
                setIsContinueDisabled(true);
            }
        } else {
            handleClose();
        }
    }

    const handleBack = () => {
        if (currentSection > 0) {
            setCurrentBlock(0);
            setCurrentSection(prev => prev - 1);
        }
    }

    const handleClose = () => {
        navigate(-1);
    }
    const optionBoxHandler = (value: any) => {
        if (value != '') {
            setIsContinueDisabled(false);
        }
    }

    const renderElement = (block: BlockProps) => {
        switch (block.type) {
            case 'text':
                if (block.subtype === 'html') {
                    return <div className={styles.rawText} dangerouslySetInnerHTML={{__html: block.value}}/>
                } else {
                    const color: Colors = block.subtype === 'caption' ? 'text-secondary-subdued' : 'text-main';
                    return <Text type={block.subtype} mb={16} color={color}>{block.value as string}</Text>;
                }
            case 'image':
                const imageSource = (block.value as string).replace('mindwayassets.s3.eu-central-1.amazonaws.com', 'd2hw9kb0hgpi5d.cloudfront.net')
                return <img src={imageSource} className={styles.blockImage}/>;
            case 'quiz':
                const quizProps: QuizProps = block.value as QuizProps;
                return <div>
                    <Text type="caption" color="primary-default" mb={8}>QUESTION</Text>
                    <Text type="h5" mb={24}>{quizProps.question}</Text>
                    <OptionBox className={styles.quiz} optionData={quizProps.optionData} onClick={optionBoxHandler}
                               mb={10}/>
                </div>
        }
    }

    return <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
    >
        <PageWrapper>
            {currentSection !== data.length && <LessonHeader
                currentIndex={currentSection}
                total={data.length}
                onBackButton={handleBack}
                onCloseButton={handleClose}
            />}
            <Box paddingBottom={96} paddingTop={24}>
                <div className={styles.sections}>
                    <motion.div
                        key={currentSection}
                        className={styles.section}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    >
                        {data[currentSection]?.blocks?.map((block: any, blockIndex: number) =>
                            <motion.div
                                key={blockIndex}
                                ref={(el: any) => blocksRefs.current[blockIndex] = el}
                                initial={{opacity: 0, display: 'none'}}
                                animate={blockIndex <= currentBlock ? {opacity: 1, display: 'block'} : {
                                    opacity: 0,
                                    display: 'none'
                                }}
                                exit={{opacity: 0, display: 'none'}}
                            >
                                {block?.elements.map((element: any) => renderElement(element))}
                            </motion.div>
                        )}
                        {data.length > 0 && currentSection === data.length && <div className={styles.lastSection}>
                            <Text type="h1">Well done! 💪</Text>
                            <Text type="medium-text">Lesson is finished</Text>
                        </div>}
                    </motion.div>
                </div>
                <FixedWrapper>
                    <Button
                        disabled={isContinueDisabled}
                        text={currentSection === 0 && currentBlock === 0 ? 'Start' : (currentSection === data.length - 1 ? 'Finish' : 'Continue')}
                        onClick={handleContinue}
                    />
                </FixedWrapper>
                <ScrollToTop/>
            </Box>
        </PageWrapper>
    </motion.div>
};

export default Lesson;
