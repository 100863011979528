import styled from 'styled-components';
import { IImage } from './index';

export const StyledImage = styled.img<IImage>`
    width: 100%;
    height: 100%;
    display: block;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
    ${({ center }) => center && 'margin-left: auto; margin-right: auto;'}
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
    ${({ objectFit }) => objectFit && `object-fit: ${objectFit};`}
`;
