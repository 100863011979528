import React, { useState, useEffect } from 'react';

import { StyledOptionBoxButton, StyledOptionBox } from './styles';
import { withTheme } from 'utils';
import { Option } from '../Option';
import { Button } from '../../Button';

import { IOptionBoxComponent, IOptionBox } from '../index';
import {useLocation} from "react-router-dom";

const OptionBoxComponent: React.FC<IOptionBoxComponent> = ({
    theme,
    multiple,
    fixed,
    onClick,
    optionData,
    buttonData,
    className,
    mb,
}) => {
    const location = useLocation();
    const [singleValue, setSingleValue] = useState('' as string);
    const [multipleValue, setMultipleValue] = useState([] as Array<string>);
    const buttonDisabledState = !multipleValue.length;

    useEffect(() => {
        setSingleValue('');
        setMultipleValue([]);
    }, [location]);


    const multipleHandleOption = (value: string, checked: boolean) => {
        const resultArr = [...multipleValue];

        if (!checked) {
            const deleteIndex = resultArr.findIndex(element => element === value);
            resultArr.splice(deleteIndex, 1);
        } else {
            resultArr.push(value);
        }
        setMultipleValue(resultArr);
    };

    const singleHandleOption = (value: string) => {
        setSingleValue(value);
    };

    const handleOption = (value: string, checked: boolean) => {
        if (multiple) {
            multipleHandleOption(value, checked);
        } else if (checked) {
            singleHandleOption(value);
        }
    };

    useEffect(() => {
        if (onClick) onClick(singleValue);
    }, [singleValue]);

    return (
        <StyledOptionBox fixed={fixed} className={className} mb={mb}>
            {optionData?.map(
                (
                    {
                        title,
                        subtitle,
                        value,
                        leftIcon,
                        leftSvg,
                        leftImage,
                        rightImage,
                        rightIcon,
                        rightSvg,
                        indicated,
                        titleType,
                        mb,
                        dataLocator,
                        rightCheckbox,
                        leftCheckbox,
                        short,
                    },
                    index,
                ) => {
                    return (
                        <Option
                            key={index}
                            onClick={handleOption}
                            title={title}
                            subtitle={subtitle}
                            value={value}
                            leftIcon={leftIcon}
                            leftSvg={leftSvg}
                            leftImage={leftImage}
                            rightImage={rightImage}
                            rightIcon={rightIcon}
                            rightSvg={rightSvg}
                            indicated={indicated}
                            leftCheckbox={leftCheckbox}
                            rightCheckbox={rightCheckbox}
                            titleType={titleType}
                            mb={mb}
                            dataLocator={dataLocator}
                            short={short}
                            borderWidth={1}
                            borderColor="border-default"
                            checkedBorderColor="primary-default"
                        />
                    );
                },
            )}
            {multiple && (
                <StyledOptionBoxButton
                    buttonData={buttonData}
                    theme={theme}
                    fixed={buttonData?.fixed}
                >
                    <Button
                        text={buttonData?.text}
                        onClick={() => onClick && onClick(multipleValue)}
                        disabled={buttonDisabledState}
                        dataLocator={buttonData?.dataLocator}
                    />
                </StyledOptionBoxButton>
            )}
        </StyledOptionBox>
    );
};

const OptionBox: React.FC<IOptionBox> = withTheme(OptionBoxComponent);

OptionBox.displayName = 'OptionBox';

export { OptionBox, OptionBoxComponent };
