import React, { ComponentType, FC } from 'react';
import { Theme } from 'types/ui';
import { defaultThemeConfig } from 'defaultTheme';

const ThemeContext = React.createContext(defaultThemeConfig);
const { Provider, Consumer } = ThemeContext;

export interface ThemePropsTypes {
    theme: Theme;
    children: React.ReactNode;
}

export type Props = { theme: Theme };

export const ThemeProvider: FC<ThemePropsTypes> = props => {
    const { theme, children } = props;

    return <Provider value={theme}>{children}</Provider>;
};

export const withTheme = <P extends Props>(WrappedComponent: FC<P> | ComponentType<P>) => {
    return (props: any) => (
        <Consumer>{theme => <WrappedComponent {...props} theme={theme} />}</Consumer>
    );
};
