import React from 'react';

import { StyledModalContainer, StyledModalBox } from './styles';
import { withTheme } from 'utils';

import SvgModalCloseComponent from './ModalClose';

import { IModalComponent, IModal } from './index';

const ModalComponent: React.FC<IModalComponent> = ({
    theme,
    isModalOpen,
    setIsModalOpen,
    maxWidth,
    paddingX = 16,
    paddingY = 16,
    withShadow = false,
    popupBackgroundColor = 'surface-main',
    borderColor = 'surface-main',
    borderRadius = 16,
    children,
    className,
}) => {
    return (
        <StyledModalContainer
            className={className}
            theme={theme}
            isModalOpen={isModalOpen}
            onClick={() => {
                setIsModalOpen(false);
            }}
        >
            <StyledModalBox
                theme={theme}
                maxWidth={maxWidth}
                paddingX={paddingX}
                paddingY={paddingY}
                borderRadius={borderRadius}
                withShadow={withShadow}
                popupBackgroundColor={popupBackgroundColor}
                borderColor={borderColor}
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
                <SvgModalCloseComponent
                    theme={theme}
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                />
                {children}
            </StyledModalBox>
        </StyledModalContainer>
    );
};

const Modal: React.FC<IModal> = withTheme(ModalComponent);

Modal.displayName = 'Modal';

export { Modal, ModalComponent };
