import React from 'react';
import { StyledHeader, StyledChildBlock } from './styles';
import { withTheme } from 'utils';

import { IHeaderComponent, IHeader } from './index';

const HeaderComponent: React.FC<IHeaderComponent> = ({
    theme,
    fixed = false,
    withShadow = false,
    backgroundColor = 'surface-default',
    left,
    right,
    className,
}) => {
    return (
        <StyledHeader
            theme={theme}
            withShadow={withShadow}
            left={left}
            right={right}
            fixed={fixed}
            backgroundColor={backgroundColor}
            className={className}
        >
            <StyledChildBlock>{left}</StyledChildBlock>
            <StyledChildBlock>{right}</StyledChildBlock>
        </StyledHeader>
    );
};

const Header: React.FC<IHeader> = withTheme(HeaderComponent);

Header.displayName = 'Header';

export { Header, HeaderComponent };
