import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as BackIcon} from './img/icon-back.svg'
import styles from './Courses.module.css'
import {Box, Button, Image, Text} from "../../../components/Common";
import {getCategoryDetails, getCategoryUserData, getCourses, getLessons} from "../../../api/appApiService";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import FixedWrapper from "../../../components/FixedWrapper";
import {Lesson} from "../../../types/appTypes";
import {ReactComponent as CheckIcon} from 'assets/img/check.svg';
import {motion} from "framer-motion";

const Courses = () => {
    const [categoryData, setCategoryData] = useState(null as any);
    const [coursesData, setCoursesData] = useState(null as any);
    const [lessonsData, setLessonsData] = useState(null as any);
    const [userCategoryData, setUserCategoryData] = useState(null as {
        lessons: { id: number, sectionsCompleted: number }[]
        lastViewedLesson: number | null
    } | null);
    const navigate = useNavigate();
    const {id} = useParams();
    const calculateProgress = (current: number, total: number) => Math.round((current / total) * 100) + '%';
    const lastViewedLesson = userCategoryData?.lastViewedLesson || null;

    useEffect(() => {
        if (id) {
            getCategoryDetails(parseInt(id)).then((data) => {
                setCategoryData(data);
            });
            getCourses(parseInt(id)).then((data) => {
                setCoursesData(data);
                getLessons(data.map((value: any) => value.id)).then((data) => {
                    setLessonsData(data);
                });
            });
            getCategoryUserData(parseInt(id)).then((data) => {
                setUserCategoryData(data);
            });
        }
    }, [id]);

    const renderLesson = (
        lessonsProgress: {
            id: number,
            sectionsCompleted: number,
        }[] | undefined,
        lesson: Lesson,
        index: number
    ) => {
        const lessonProgressData = lessonsProgress?.find(lp => lp.id === lesson.id)
        const progress = calculateProgress(lessonProgressData?.sectionsCompleted || 0, lesson.total_sections)
        const isCompleted = lessonProgressData?.sectionsCompleted === lesson.total_sections;

        return <div onClick={() => navigate('/lesson/' + lesson.id)}>
            <div className={styles.lessonImage}>
                <Image
                    src={lesson.image}
                    alt={lesson.name}
                    className={styles.lessonImageImg}
                />
                <div className={styles.lessonImageCover}/>
            </div>
            <Text type="caption" color="text-secondary-subdued" mb={4}>Lesson {index + 1}</Text>
            <div className={styles.progressWrapper}>
                <div className={styles.progressBox}>
                    <div className={styles.progressBar}
                         style={{width: progress}}/>
                </div>
                <div className={styles.progressStatus}>
                    {isCompleted ? <CheckIcon/> : <Text type="caption" color="text-secondary-subdued">{progress}</Text>}
                </div>
            </div>
            <Text type="medium-text" mb={24} bold>{lesson.name}</Text>
        </div>
    }

    return <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0,transition: { duration: 1 }}}>
        {categoryData &&
            <Box paddingBottom={96}>
                <div className={styles.wrapperTop}>
                    <div>
                        <div className={styles.topButton} onClick={() => navigate(-1)}>
                            <BackIcon/>
                        </div>
                    </div>
                </div>
                <div className={styles.imageWrapper}>
                    <img src={categoryData.image} alt={categoryData.title}/>
                </div>
                <div className={styles.wrapperBottom}>
                    <div className={styles.info}>
                        <Text type="h4" mb={16} mt={24}>{categoryData.name}</Text>
                        <Text type="medium-text">{categoryData.shortDescription}</Text>
                        <Text type='small-text' color={'text-secondary-subdued'} mt={4}>
                            {categoryData.description}
                        </Text>
                    </div>
                    {coursesData && coursesData.map((course: any) => <div>
                        <Text type="h6" mb={24} mt={34}>{course.name}</Text>
                        {lessonsData && <div className={styles.lessons}>
                            {lessonsData[course.id].map((lesson: Lesson, index: number) => (
                                <div className={styles.lesson}>
                                    {renderLesson(userCategoryData?.lessons, lesson, index)}
                                </div>))}
                        </div>}
                        <FixedWrapper>
                            <Button
                                text={lastViewedLesson ? 'Continue Learning' : 'Start Course'}
                                onClick={() => navigate('/lesson/' + lastViewedLesson)}
                            />
                        </FixedWrapper>
                    </div>)}
                </div>
                <ScrollToTop/>
            </Box>
        }
    </motion.div>
};

export default Courses;
