import React, { ReactElement } from 'react';
import { StyledImageBox } from '../styles';
import { Image } from '../../../Image';

export function StyledImageBoxComp({
    image,
    left,
    borderWidth,
}: {
    image: string;
    left?: boolean;
    borderWidth?: number;
}): ReactElement {
    return (
        <StyledImageBox left={left} borderWidth={borderWidth}>
            <Image src={image} alt="im" maxHeight={80}/>
        </StyledImageBox>
    );
}
