import {transparentize} from "utils/ui";
import styled from 'styled-components';
import { IStyledText } from './index';
import { TextType } from 'types/commonTypes';

const getEllipsis = (ellipsis: number, type: TextType = 'medium-text') => {
    let maxHeight;
    switch (type) {
        case 'h1':
            maxHeight = 52 * ellipsis;
            break;
        case 'h2':
            maxHeight = 44 * ellipsis;
            break;
        case 'h3':
            maxHeight = 40 * ellipsis;
            break;
        case 'h4':
            maxHeight = 36 * ellipsis;
            break;
        case 'h5':
            maxHeight = 32 * ellipsis;
            break;
        case 'h6':
            maxHeight = 32 * ellipsis;
            break;
        case 'small-text':
            maxHeight = 20 * ellipsis;
            break;
        case 'medium-text':
            maxHeight = 24 * ellipsis;
            break;
        case 'large-text':
            maxHeight = 28 * ellipsis;
            break;
        case 'small-button':
            maxHeight = 16 * ellipsis;
            break;
        case 'medium-button':
            maxHeight = 24 * ellipsis;
            break;
        case 'caption':
            maxHeight = 16 * ellipsis;
            break;
        case 'link':
            maxHeight = 16 * ellipsis;
            break;
        case 'overline':
            maxHeight = 16 * ellipsis;
            break;
        default:
            maxHeight = 24 * ellipsis;
    }
    
return `
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: ${ellipsis};
        max-height: ${maxHeight}px;
  `;
};
const getType = (type: TextType = 'medium-text') => {
    switch (type) {
        case 'h1': {
            return `font-size: 40px;
                    line-height: 52px;
                    font-weight: 900;`;
        }
        case 'h2': {
            return `font-size: 36px;
                    line-height: 44px;
                    font-weight: 700;`;
        }
        case 'h3': {
            return `font-size: 32px;
                    line-height: 40px;
                    font-weight: 700;`;
        }
        case 'h4': {
            return `font-size: 28px;
                    line-height: 36px;
                    font-weight: 700;`;
        }
        case 'h5': {
            return `font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;`;
        }
        case 'h6': {
            return `font-size: 21px;
                    line-height: 32px;
                    font-weight: 700;`;
        }
        case 'small-text': {
            return `font-size: 14px; 
                    line-height: 20px;`;
        }
        case 'medium-text': {
            return `font-size: 16px;
                    line-height: 24px;`;
        }
        case 'xlarge-text': {
            return `font-size: 28px;
                    line-height: 36px;`;
        }
        case 'large-text': {
            return `font-size: 18px;
                    line-height: 28px;`;
        }
        case 'small-button': {
            return `font-size: 14px;
                    line-height: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 1px;`;
        }
        case 'medium-button': {
            return `font-size: 17px;
                    line-height: 24px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 2px;`;
        }
        case 'caption': {
            return `font-size: 12px;
                    line-height: 16px;`;
        }
        case 'caption-small': {
            return `font-size: 10px;
                    line-height: 12px;`;
        }
        case 'link': {
            return `font-size: 12px;
                    line-height: 16px;
                    text-decoration: underline;`;
        }
        case 'overline': {
            return `font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 1px;`;
        }
        default:
            return `font-size: 16px;
                    line-height: 24px;`;
    }
};

export const StyledText = styled.p<IStyledText>`
    font-family: 'DM Sans', sans-serif;
    color: ${({ color = 'text-main', theme, opacityValue }) =>
        opacityValue && theme[color] ? transparentize(opacityValue, theme[color]) : theme[color]};
    ${({ center }) => center && 'text-align: center;'};
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
    ${({ bold }) => bold && 'font-weight: 700;'};
    ${({ italic }) => italic && 'font-style: italic;'};
    ${({ type }) => getType(type)};
    ${({ mt }) => mt && `margin-top: ${mt}px;`};
    ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
    ${({ ellipsis, type }) => ellipsis && getEllipsis(ellipsis, type)};
    ${({ inline }) => inline && 'display: inline'};
`;
